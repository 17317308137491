<script setup>
import {defineEmits} from 'vue';
import {useStore} from 'vuex';
/* Emits */
const emit = defineEmits(['closeModal']);
/* Data */
const store = useStore();
const officeViewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${window.location.origin}/documents/tutorials/${store.state.locale}/Instructions_2025.pptx`
/* Methods */
const closeTutorialModal = () => 
{
    emit('closeModal')
};
</script>
<template>
    <div class="fixed inset-0 z-50 bg-white flex flex-col space-y-5 p-10">
        <div class="flex w-full items-center justify-end"> 
            <button 
                title="CloseModal"
                @click.prevent="closeTutorialModal"
                class="ml-auto bg-transparent min-w-min flex items-center justify-center transform hover:rotate-90 duration-300">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" class="w-4 fill-current text-gray-800 dark:text-gray-400">
                        <path d="M34.5,32L62.2,4.2c0.7-0.7,0.7-1.8,0-2.5c-0.7-0.7-1.8-0.7-2.5,0L32,29.5L4.2,1.8c-0.7-0.7-1.8-0.7-2.5,0
                            c-0.7,0.7-0.7,1.8,0,2.5L29.5,32L1.8,59.8c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5L32,34.5l27.7,27.8
                            c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.8,0-2.5L34.5,32z"/>
                    </svg>
            </button>
        </div>
        <div class="flex flex-col space-y-2 h-full">
            <iframe
                :src="officeViewerUrl"
                class="h-full w-full"
                frameborder="0"
                allowfullscreen
                ></iframe>
            <!-- <iframe v-else loading="lazy" class="h-full"
                src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFTcNWT5Yg&#x2F;view?embed" allowfullscreen="true" allow="fullscreen">
            </iframe> -->
        </div>
    </div>
</template>